<template>
  <div class="d-flex w-100 justify-content-between">
    <div class="d-flex">
      Overview of key statistic metrics of Loyalty System.
    </div>
    <div class="d-flex align-items-center">
      <m-date-select v-model="dateRange" />
      <b-button
        variant="action"
        v-b-tooltip.hover="'Download CSV'"
        @click="download"
        class="ml-3"
        style="height: 100%"
      >
        Export to CSV
      </b-button>
    </div>
  </div>
</template>

<script>
import MDateSelect from "../gadgets/MDateSelect";
export default {
  name: "StatisticsHeader",
  components: { MDateSelect },
  computed: {
    dateRange: {
      get() {
        return this.$store.state.statistics.dateRange;
      },
      set(value) {
        this.$store.commit("statistics/UPDATE_STATISTICS_FIELD", {
          field: "dateRange",
          value,
        });
      },
    },
  },
  watch: {
    dateRange() {
      this.$store.dispatch("statistics/getStatistics");
    },
  },
  methods: {
    download() {
      console.log("Download");
    },
  },
};
</script>

<style scoped></style>
