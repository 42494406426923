<template>
  <b-overlay :show="loading" rounded="sm" class="h-100">
    <div class="m-chart-card p-4 h-100">
      <div class="title">{{ title }}</div>
      <slot></slot>
    </div>
  </b-overlay>
</template>

<script>
export default {
  name: "ChartCard",
  props: {
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.m-chart-card {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 475px;

  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
</style>
