<template>
  <div class="d-flex">
    <statistics-card
      title="Registered users"
      :value="statisticsData.registeredUsers"
      class="col-2 mr-3"
    />
    <statistics-card
      title="Claimed points"
      :value="statisticsData.claimedPoints"
      class="col-2 mr-3"
    />
    <statistics-card
      title="Used points"
      :value="statisticsData.usedPoints"
      class="col-2 mr-3"
    />
  </div>
</template>

<script>
import StatisticsCard from "./StatisticsCard";
export default {
  name: "StatisticsCards",
  components: { StatisticsCard },
  computed: {
    statisticsData() {
      return this.$store.state.statistics.data;
    },
  },
};
</script>

<style scoped></style>
