<template>
  <div class="w-100 d-flex flex-wrap mx-0 p-0">
    <chart-card
      title="Claimed points source"
      class="col-4 p-0 p-2"
      :loading="isRequesting"
    >
      <apexchart
        width="100%"
        type="pie"
        :options="claimedPointsSource"
        :series="claimedPointsSource.series"
        v-if="claimedPointsSource.series.length"
      ></apexchart>
      <span v-else>No data for selected timespan</span>
    </chart-card>
    <chart-card
      title="Claimed/Used points"
      class="col-8 p-0 p-2"
      :loading="isRequesting"
    >
      <apexchart
        width="100%"
        type="area"
        :options="pointsPerDay.options"
        :series="pointsPerDay.series"
      ></apexchart>
    </chart-card>

    <chart-card
      title="New clients per day"
      class="col-5 p-0 p-2"
      :loading="isRequesting"
    >
      <apexchart
        width="100%"
        type="line"
        :options="clientsPerDay.options"
        :series="clientsPerDay.series"
      ></apexchart>
    </chart-card>
    <chart-card
      title="Claimed points per product"
      class="col-4 p-0 p-2"
      :loading="isRequesting"
    >
      <apexchart
        width="100%"
        type="pie"
        :options="claimedPointsProductsSource"
        :series="claimedPointsProductsSource.series"
        v-if="claimedPointsProductsSource.series.length"
      ></apexchart>
      <span v-else>No data for selected timespan</span>
    </chart-card>
    <chart-card
      title="Generated codes (all time)"
      class="col-3 p-0 p-2"
      :loading="isRequesting"
    >
      <apexchart
        width="100%"
        type="pie"
        :options="generatedCodes"
        :series="generatedCodes.series"
      ></apexchart>
    </chart-card>
  </div>
</template>

<script>
import ChartCard from "./charts/ChartCard";
export default {
  name: "StatisticsCharts",
  components: { ChartCard },
  computed: {
    isRequesting() {
      return this.$store.state.statistics.requesting;
    },
    statisticsData() {
      return this.$store.state.statistics.data;
    },
    claimedPointsSource() {
      return {
        series: [
          this.statisticsData.claimedPointsSource.promotions,
          this.statisticsData.claimedPointsSource.purchase,
          this.statisticsData.claimedPointsSource.code,
          this.statisticsData.claimedPointsSource.other,
        ],
        labels: ["Promotions", "Purchase", "Codes", "Other"],
      };
    },
    claimedPointsProductsSource() {
      return {
        series: this.statisticsData.tokensPerProducts.map((el) =>
          parseInt(el.sum)
        ),
        labels: this.statisticsData.tokensPerProducts.map((el) => el.name),
        legend: {
          position: "bottom",
        },
      };
    },
    generatedCodes() {
      return {
        series: [
          this.statisticsData.allCodes - this.statisticsData.usedCodes,
          this.statisticsData.usedCodes,
        ],
        labels: ["Unused codes", "Used codes"],
        legend: {
          position: "bottom",
        },
      };
    },
    clientsPerDay() {
      return {
        options: {
          chart: {
            id: "clients-per-day",
            height: "350px",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },
          stroke: {
            width: 2.5,
          },
          xaxis: {
            categories: this.statisticsData.usersPerDay.date,
          },
          dataLabels: {
            enabled: false,
          },
        },
        series: [
          {
            name: "clients",
            data: this.statisticsData.usersPerDay.values,
          },
        ],
      };
    },
    pointsPerDay() {
      return {
        options: {
          chart: {
            id: "points-per-day",
            height: "350px",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350,
              },
            },
          },

          stroke: {
            width: 2.5,
          },

          xaxis: {
            categories: this.statisticsData.usersPerDay.date,
          },
          dataLabels: {
            enabled: false,
          },
        },
        series: [
          {
            name: "Used points",
            data: this.statisticsData.usedPerDay.values,
          },
          {
            name: "Claimed points",
            data: this.statisticsData.claimedPerDay.values,
          },
        ],
      };
    },
  },
};
</script>

<style scoped></style>
