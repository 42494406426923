<template>
  <div class="d-flex flex-column p-3 m-card">
    <span class="title">{{ title }}</span>
    <span class="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",
  props: {
    title: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
  },
};
</script>

<style scoped lang="scss">
.m-card {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .title {
    font-size: 1.1rem;
    font-weight: normal;
  }
  .value {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
</style>
