<template>
  <div class="p-4">
    <statistics-header />
    <statistics-cards class="mb-5" />
    <statistics-charts />
  </div>
</template>

<script>
import StatisticsHeader from "../components/statistics/StatisticsHeader";
import StatisticsCards from "../components/statistics/StatisticsCards";
import StatisticsCharts from "../components/statistics/StatisticsCharts";
export default {
  name: "MStatistics",
  components: { StatisticsCharts, StatisticsCards, StatisticsHeader },
  created() {
    this.$store.dispatch("statistics/getStatistics");
  },
};
</script>

<style scoped></style>
