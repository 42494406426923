<template>
  <v-date-picker
    color="red"
    is-range
    :value="value"
    @input="$emit('input', $event)"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <b-input-group>
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-calendar-day"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="dateRange"
          type="text"
          size="sm"
          class="border-left-0 border-right-0"
          readonly
          :class="inputClass"
          :value="inputValue.start ? getDate(inputValue) : ''"
          @focus="togglePopover"
          @blur="togglePopover"
        ></b-form-input>
        <b-input-group-prepend>
          <div class="m-input__icon right show-cursor" @click="$emit('clear')">
            <i class="fal fa-times"></i>
          </div>
        </b-input-group-prepend>
      </b-input-group>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  name: "MDateSelect",
  props: {
    value: {
      type: Object,
      required: true,
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    getDate(dateRange) {
      return `${this.$options.filters.date(
        dateRange.start
      )} - ${this.$options.filters.date(dateRange.end)}`;
    },
  },
};
</script>

<style scoped></style>
